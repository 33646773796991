* {
    font-family: consolas, sans-serif;

}

::selection {
    background-color: rgba(0, 0, 0, 0.3) !important;
    color: #fff !important;
}

h1  {
    font-size: 2em !important;
    font-weight: bold !important;
    letter-spacing: normal !important;
}

h2  {
    font-size: 2em !important;
    font-weight: bold !important;
    letter-spacing: normal !important;
}

p {
    font-family: consolas, sans-serif;
    font-size: 1em !important;
    letter-spacing: normal !important;
}