
.video-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	overflow: hidden;
}

.video-wrapper > .content {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	text-align: center;
	z-index: 99;
}

.video-wrapper > .video-pattern {
	position: absolute;
	z-index: -2;
	background: transparent url("../../public/dot.png") repeat 0 0;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 2;
	opacity: 1;
}

.video-wrapper > .background > video {
	position: absolute;
	top: 50%;
	left: 50%;
	min-width: 135%;
	min-height: 110%;
	width: auto;
	height: auto;
	transform: translate(-50%, -50%);
	background-size: cover;
	-webkit-filter: blur(5px);
	-moz-filter: blur(5px);
	-o-filter: blur(5px);
	-ms-filter: blur(5px);
	filter: blur(5px);
	overflow: hidden;
}
